header{
  /* position: relative; */
  z-index: 9999;
  background-color: transparent;
  backdrop-filter: blur(5px);
  -webkit-box-shadow: 0px 8px 13px 1px rgba(112,112,112,0.75); 
box-shadow: 0px 8px 13px 1px rgba(112,112,112,0.75);
position: fixed;
  top: 0;
  width: 100%;
  /*
  width: 100%; */
}
.header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.logo {
  padding: 1em 0;
}
.iconContainer {
  display: none;
}
.menu {
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.menu ul li {
  display: inline;
}
.menu ul li a {
  color: #7C8340;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  padding: 1em 1em 0.4em 1em;
  display: inline-block;
}

.menu ul li a:hover {
  border-bottom: 1px solid #000;
}

.iconButton{
  margin-top: 0.8em !important;
}

.redesSociais{
  display: flex;
}

.redesSociais img{
margin: 0 5px;
}

@media (max-width: 968px) {
  .header {
    height: 70px;
  }
  .menu {
    display: none;
  }
  .iconContainer {
    display: block;
  }
}
