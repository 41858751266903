.generalContent {
    width: 100%;
    padding: 3em;
    margin-top: 5em;
  }
  
  .tituloAssoolive {
    color: #385527;
    font-size: 38px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    text-align: center;

  }
  .subtituloApresentacao {
    color: #ffffff;
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  .contentAssociados{
    display: flex;
  }
  .logoParceiros{
    margin: 2em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 70%;
  }

  .tituloConteudosDeInteresse{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #385527;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-top: 3em;
  }

  .legendaConteudosDeInteresse{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .boxArticle{
    width: 100%;
    border: 1px solid #70707080;
    height: 120px;
    padding: 2em;
    box-sizing: border-box;
    margin-bottom: 1em;
  }

  .boxArticle h3{
    color: #A98A2E;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  .boxArticle a{
    color: #808080;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  @media (max-width: 998px) {
  }
  