.generalContent {
    width: 100%;
    padding: 3em;
  }
  
  .tituloAssoolive {
    color: #385527;
    font-size: 38px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    text-align: center;

  }
  .subtituloApresentacao {
    color: #ffffff;
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  .imageParceiros{
    margin:1em auto;
  }
  @media (max-width: 998px) {
  }
  