.generalContent {
    width: 100%;
    padding: 3em;
  }
  .tituloNotasPercebidas {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #385527;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .subTituloNotasPercebidas {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #385527;
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-top: 1em;
  }

  .legendaNotasPercebidas {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .qualidadeCafe{
      width: 60%;
      margin: 0 auto;
  }
  
  .rodapeQualidade{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size:13px;
    font-family: "Open Sans", sans-serif;
    margin-top: 1em;
  }

  .linkNoticia{
    text-decoration: none;
    color:#616467;
  }
  @media (max-width: 998px) {
    .generalContent {
      padding: 3em 1em;
    }
  }
  