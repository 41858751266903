.separador{
    height: 65px;
    width: 100%;
    background-color: #A98A2E;
}


.tituloAssoolive {
    color: #385527;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .textoAssoolive {
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  .imagemNossoAzeite{
    margin-left: 0.8em;
  }
  @media (max-width: 998px) {
    .imagemNossoAzeite{
      margin-left: 0;
    }
  }
  