.generalContent {
  background: url("../../Assets/banner-apresentacao.png");
  background-repeat: no-repeat;
  background-size: 101% 100%;
  height: 100vh;
}

.tituloApresentacao {
  color: #ffffff;
  font-size: 38px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  margin-top: 40%;
}
.subtituloApresentacao {
  color: #ffffff;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

@media (max-width: 998px) {

  .generalContent {
    background: url("../../Assets/banner-apresentacao-mobile.png");
    margin-bottom: 1em;
    height: 70vh;
    margin-top: 0;
  }
  .tituloApresentacao {
    margin-top: 6em;
  }
}
