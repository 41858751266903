.generalContent {
    width: 100%;
    padding: 3em;
  }
  .tituloRiqueza {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #385527;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 1em;
  }
  
  .textoRiqueza {
    font-size: 15px;
    color: #000;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    margin-top: 1em;
  }

  .subtitleRiqueza{
    color: #385527;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 1em;
  }
  
  .imageRiquezas{
      margin: 0 auto;
      width: 60%;
  }

  .imageRiquezasSecondContent{
    margin: 0 auto;
    width: 60%;
  }
  
  @media (max-width: 998px) {
    .tituloRiqueza{
      text-align: center;
    }
  }
  