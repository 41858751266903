
  .tituloAssoolive {
    color: #385527;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin-bottom: 1em;
  }
  .textoAssoolive {
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  
  .textoAssooliveRodape{
    font-size: 14px;
    line-height: 28px;
    font-style: italic;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  @media (max-width: 998px) {
  }
  