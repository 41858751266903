.generalContent {
    background: url("../../Assets/bg-serra-da-mantiqueira.jpg");
    background-repeat: no-repeat;
    background-size: 101% 100%;
    height: 80vh;
    position: relative;
  }

  .contentText{
      position: absolute;
      top: 2em;
      right: 0;
      background-color: #0D2D5450;
      width: 500px;
      height: 350px;
      padding: 3em;
      box-sizing: border-box;
  }

  .titleSerraDaMantiqueira{
    color: #0D2D54;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
  }

  .textSerraDaMantiqueira {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #fff;
  }

  @media (max-width: 998px) {
    .generalContent {
      background: url("../../Assets/bg-serra-da-mantiqueira-mobile.png");
      height: 500px;
    }
    .contentText{
      top: 2em;
      width: 90%;
      height: 400px;
    }
  }
  