.generalContent{
  width: 100%;
  display: flex;
  height: 300px;
  justify-content: space-between;
  background-color: #302917;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  padding: 3em;
} 

.logoRadar{
  width: 60%;
}

.redesSociais{
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5em;
}

.redesSociais h4{
  color:#A98A2E;
  font-weight: 600;
  font-size:22px;
}

.redesSociais h5{
  color:#A98A2E;
  font-weight: 600;
  font-size:14px;
}

.redesSociais img{
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  margin: 1em 0 0 0.5em;
}

.logosRedesSociais{
  display: flex;
}

.assoolive img{
  margin: 0 auto;
}

@media (max-width: 998px) {
  .generalContent{
    padding: 1em;
    height: 500px;
  } 
  .redesSociais{
    margin-top: 2em;
  }
  .assoolive img{
    margin: 2em auto 0 auto;
  }
}
