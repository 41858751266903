.generalContent {
    background: url("../../Assets/capa_sobre.png");
    background-repeat: no-repeat;
    background-size: 101% 100%;
    height: 400px;
    margin-bottom: 5em;
  }
  
  @media (max-width: 998px) {
    .generalContent {
      background: url("../../Assets/capa_sobre_mobile.png");
      background-size: cover;
    }
  }