.generalContent {
  width: 100%;
  padding: 3em;
  background-color: #0d2d54;
  margin-top: 3em;
}

.tituloValores{
    font-size: 22px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color:#fff;
    text-align: center;
}

.textoValores{
    text-align: center;
    color: #fff;
} 
.imagemValores{
    width: 60%;
    margin: 0 auto;
}

@media (max-width: 998px) {
}
