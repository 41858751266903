@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

*{
  box-sizing: border-box;
}

body {
  margin: 0px;
  color: #333;
  --type-first: 'Open Sans', sans-serif;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}


button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}


.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  max-width: 2000px; 
}

.outerLista {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  max-width: 5000px; 
  background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.outerLager {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  max-width: 1000px; 
  background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 10px 10px 10px 10px;
}

.outerSecond {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.innerLista {
  width: 1800px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.innerLager {
  width: 1000px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}
.each-slide{
  padding: 3em;
  text-align: center;
}

.titleSection{
  color: #000000;
  font-family: "Lato", verdana;
  font-weight: 900;
  font-size: 34px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
}

.titleSection::after{
  content: '';
  display: block;
  width: 3rem;
  height: 1px;
  background: #000000;
  text-align: center;
  margin: 1em auto;
}

.subTitleSection{
  color:#404041;
  font-size: 18px;
  font-style: italic;
  letter-spacing: .4px;
  line-height: 26px;
  text-align: center;
}

.titleRodape{
  color: #000000;
  font-family: "Lato", verdana;
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
}

.titleRodape::after{
  content: '';
  display: block;
  width: 3rem;
  height: 1px;
  background: #000000;
  text-align: center;
  margin: 1em auto;
}
@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}
a {
  text-decoration: none;
  color: #333;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

.title {
  font-family: var(--type-first);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: #fb1;
  position: absolute;
  bottom: 5px;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.headerLogo{
  display: flex;
  justify-content: space-between;
}

.rotate{
  transform: initial;
  animation: rotate 0.5s;
}

@keyframes rotate {
  to {
    transform: rotateY(180deg);
  }
}

.desrotate{
  transform: initial;
  animation: rotate 0.5s;
}

@keyframes desrotate {
  to {
    transform: rotateY(-180deg);
  }
}