.contentProcessoArtesanal {
    background: url("../../Assets/processo-artesanal.jpg");
    background-repeat: no-repeat;
    background-size: 101% 100%;
    height: 300px;
    display: flex;
  }
  .contentFrescor {
    background: url("../../Assets/frescor.jpg");
    background-repeat: no-repeat;
    background-size: 101% 100%;
    height: 300px;
    display: flex;
  }
  .contentSustentavel {
    background: url("../../Assets/sustentavel.jpg");
    background-repeat: no-repeat;
    background-size: 101% 100%;
    height: 300px;
    display: flex;
  }
  
  .tituloQualidade {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin: 0 auto;
  }

  @media (max-width: 998px) {
    .contentProcessoArtesanal {
      background: url("../../Assets/processo-artesanal-mobile.png");
      background-size: cover;
    }
    .contentFrescor {
      background: url("../../Assets/frescor-mobile.png");
      background-size: cover;
    }
    .contentSustentavel {
      background: url("../../Assets/sustentavel-mobile.png");
      background-size: cover;
    }
    
  }