.generalContent {
  background: url("../../Assets/banner-selo.png");
  background-repeat: no-repeat;
  background-size: 101% 100%;
  height: 700px;
}

.tituloSelo {
  color: #ffffff;
  font-size: 38px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}
.textoSelo {
  color: #ffffff;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
}

.linkSelo{
  text-decoration: none;
  color: #fff;
}

.linkSelo:hover{
  text-decoration: none;
}
.selo {
  margin: 0 auto;
  width: 30%;
}

@media (max-width: 998px) {
  .generalContent {
    background: url("../../Assets/banner-selo-mobile.png");
    height: 500px;
  }
  .tituloSelo {
    margin-top: 2em;
  }
  .selo {
    width: 50%;
  }
}
