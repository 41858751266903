.logo {
  padding: 0 10px;
}

.menuLateral {
  width: 20em;
  max-width: 18em;
  background-color: #fff;
  position: relative;
  z-index: 9999;
}

.containerMenu {
  display: flex;
  flex-direction: column;
  margin-top: 4em
}
.link {
  color: #385527 !important;
  text-decoration: none;
  font-size: 10px !important;
  font-weight: bold !important;
  font-family: "Bebas Neue", cursive;
  
}

.link:hover {
  text-decoration: none !important;
  color: #000;
}

.redesSociais {
  padding: 0.5em;
  display: flex;
}

.redesSociais img {
  margin: 0 5px;
}
