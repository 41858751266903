
  .tituloAssoolive {
    color: #385527;
    font-size: 30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 1em;
    margin-top: 3em;
  }
  .textoAssoolive {
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  
  @media (max-width: 998px) {
  }
  