.generalContent {
  width: 100%;
  background-color: #0D2D54;
  padding: 3em 0 1em 0;
  box-sizing: border-box;
  height: fit-content;
}


.titleForm{
  font-size: 40px;
  color: #A98A2E;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.subTitleForm{
  font-size: 24px;
  color: #A98A2E;
}

.forms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8em;
}

.twoLines{
  display: flex;
}

.lineRadioButton{
  position: relative;
  display: flex;
}

.inputForm {
  font-family: "Montserrat", sans-serif;
  border-radius: 3em;
  width: 100%;
  height: 25px;
  padding: 1em;
  box-sizing: border-box;
  transition: 0.2s;
  background-color: #99A9BD;
  border: 1px solid #707070;
  font-weight: bold;
  color: #fff;
  font-size: 15px;
  margin: 1em 0;
}
.inputForm:focus,
.inputForm:hover {
  outline: none;
}
.inputForm::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #fff;
}
.inputForm::-moz-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #000;
}

.inputRadio{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  background-color: #99A9BD !important;
  border-radius: 50%;
  border: solid 2px;
  vertical-align: bottom;
} 
.selectForm{
  background-color: #99A9BD !important;
  border-radius: 10px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 0.3em;
  margin: 1em 0;
}
.textAreaForm{
  font-family: "Open Sans", sans-serif;
  border-radius: 3em;
  width: 100%;
  height: 100px;
  min-height: 140px;
  padding: 0.8em;
  box-sizing: border-box;
  transition: 0.2s;
  background-color: #99A9BD;
  border: 2px solid #d4dae3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  margin: 1em 0;
  border-radius: 15px;
}

.textAreaForm:focus,
.textAreaForm:hover {
  outline: none;
}
.textAreaForm::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.textAreaForm::-moz-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}


.buttonForm {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: normal;
  font-family: "Open Sans", sans-serif;
  width: 120px;
  height: 40px;
  color: #fff;
  background-color: #A98A2E;;
  border-radius: 2.5em;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 1.5em;
}

.buttonForm:hover,
.buttonForm:focus {
  outline: none;
}

.imagemContato{
  margin-left: 0.8em;
  height: 100%;
}

.textCheckBox{
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin-left: 2em;
}
@media (max-width: 998px) {
  .boxImage{
    margin-top: -1em;
  }
  .boxImage img{
    width: 80%;
    margin: 0 auto;
  }
  .headerSection{
    margin: 1em auto 0em auto;
  }
  .titleSection {
    font-size: 23px;
  }
  .forms {
    margin-bottom: 3em;
  }
  .inputForm {
    width: 100%;
    height: 40px;
  }
  .textAreaForm{
    width: 100%;
    margin-bottom: 2em;
    height: 100px;
  }
  .buttonForm {
    margin: 1em 0;
    height: 40px;
    font-size: 16px;
  }
  .imagemContato{
    margin-left: 0;
    height: inherit;
  }
  .inputRadio{
    top: 0;
  }
  .selectForm{
    padding: 0.5em;
  }
}
