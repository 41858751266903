.generalContent {
    width: 100%;
    padding: 3em;
    background-color: #A98A2E;
  }
  
  .tituloAzeitona{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
  }

  .legendaAzeitona{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
  }

  .content{
    position: relative;
  }
  .imageContent{
    margin-top: 2em;
    cursor: pointer;
  }
  .titleImage{
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    position: absolute;
    top: 50%;
    left: 35%;
  }
  .contentBlue{
    width: 100%;
    height: 100%;
    min-height: 350px;
    max-height: 350px;
    background-color: #0D2D54;
    padding: 2em;
    box-sizing: border-box;
    margin-top: 2em;
  }

  .titleContentBlue{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
  }

  .textContentBlue{
    color: #fff;
    text-align: center;
  }
  .rotate{
    transform: initial;
    animation: rotate 0.10s;
  }

  @keyframes rotate {
    to {
      transform: rotateY(360deg);
    }
  }

  .desrotate{
    transform: initial;
    animation: rotate 0.5s;
  }
  
  @keyframes desrotate {
    to {
      transform: rotateY(-180deg);
    }
  }

  @media (max-width: 998px) {
    .titleImage{
      left: 25%;
    }
    .contentBlue{
      max-height: fit-content;
    }
  }
  