.generalContent {
  width: 100%;
  padding: 3em;
}
.tituloSustentabilidade {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #385527;
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 1em;
}

.textoSustentabilidade {
  font-size: 15px;
  color: #000;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
}

@media (max-width: 998px) {
  .generalContent {
    padding: 3em 1em;
  }
  .tituloSustentabilidade {
    display:inherit;
  }
  .textoSustentabilidade{
    text-align: left;
  }
  
}
