.generalContent {
  width: 100%;
  padding: 3em;
}
.tituloOlivoturismo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #385527;
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 1em;
}

.textoOlivoturismo {
  font-size: 15px;
  color: #000;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
}

.contentBanner{
  background: url("../../Assets/bg-olivoturismo.jpg");
  background-repeat: no-repeat;
  background-size: 101% 100%;
  height: 60vh;
}

@media (max-width: 998px) {
  .generalContent {
    padding: 3em 1em;
  }
  .tituloOlivoturismo{
    text-align: left;
    display: inherit;
  }
  .contentBanner{
    background: url("../../Assets/bg-olivoturismo-mobile.png");
    background-size: cover;
  }
  
}
